<script setup lang="ts">
	import { useEquipStore } from "@/store/equipStore";
	import { useUserStore } from "@/store/userStore";
  import CommonUtils from "~/utils/common-utils";

	const equipStore = useEquipStore();
	const userStore = useUserStore();

	// const dataList = await equipStore.getEquipList(userStore.loginUserInfo?.userSeq as number);
	const route = useRoute();
	const router = useRouter();
	const siteList = computed(() => {
		return equipStore.convertSiteList;
	});

	// function siteAddrSub(siteAddr: string) {
    
	// 	if (!siteAddr) return "";
	// 	let siteSplit = siteAddr?.split(" ") as string[];
	// 	let siteString = "";
	// 	if (siteSplit[0] === "충청북도") siteString = "충북";
  //   else if(siteSplit[0] === "강원특별자치도") siteString = "강원";
	// 	else siteString = siteSplit[0];
	// 	return `${siteString}/${siteSplit[1]}/${siteSplit[2]}`;
	// }

	const siteToggle = (targetClass: string) => {
		const shared = document.getElementsByClassName("sitelist-layer-wrap")[0] as HTMLElement;
		const html = document.getElementsByTagName("html")[0] as HTMLElement;

		switch (targetClass) {
			case "btn-share": {
				shared.classList.remove("close");
				html.classList.add("overflow");
				break;
			}
			case "btn-close": {
				shared.classList.add("close");
				html.classList.remove("overflow");
				break;
			}
			case "blank": {
				shared.classList.add("close");
				html.classList.remove("overflow");
				break;
			}
		}
	};

	onMounted(() => {
		nextTick(() => {});
	});

	function selectSite(siteName: string) {
		equipStore.setSelectSite(siteName);
		// await equipStore.getEquipList(userStore.loginUserInfo?.userSeq as number);

		siteToggle("blank");

		// router.push('./')
	}

	defineExpose({
		siteToggle,
	});
</script>

<template>
	<div class="layer-wrap share-box close sitelist-layer-wrap hide-site">
		<div class="layer-cnt">
			<div class="tit">{{ `발전사이트 선택` }}</div>
			<div class="cnt-sch">
				<div class="site-list1">
					<ul class="article">
						<div class="siteListWarper">
							<!-- <li  class="siteList" v-for="data in siteList" @click="selectSite(data.siteName)">
								<a
									><span class="area">{{ siteAddrSub(data.siteAddr as string) }}</span>
									{{ `${data.siteName}(${data.equipList.length})` }}
								</a>
							</li> -->
							<li v-for="(site, i) in siteList" class="siteList" @click="selectSite(site.siteName)">
								<a
									><span class="area">{{ CommonUtils.setAddrString(site.siteAddr as string) }}</span>
									{{ `${site.siteName}` }}
								</a>
							</li>
							<!-- <li  class="siteList" @click="selectSite(siteList[0].siteName)">
								<a
									><span class="area">{{ siteAddrSub(siteList[0].siteAddr as string) }}</span>
									{{ `${siteList[0].siteName}` }}
								</a>
							</li> -->
						</div>
						<!-- <NuxtLink v-if="siteList.length > 1" to="./" class="btn-st btn-1 bg-primary text-white tomonitoring" @click="">발전현황</NuxtLink> -->
					</ul>
				</div>
			</div>
			<button type="button" class="btn-close" @click="siteToggle('btn-close')">닫기</button>
		</div>
		<span class="blank" @click="siteToggle('blank')"></span>
	</div>
</template>

<style lang="scss" scoped>
	.tit {
		border-bottom: 2px solid #313131 !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	.site-list1 > ul {
		overflow-y: auto;
	}
	.site-list1 > ul > li > a {
		display: flex;
		align-items: center;
		height: 50px;
		padding: 0 20px;
		border-bottom: 1px solid #ebebeb;
		font-weight: 500;
		cursor: pointer;
		-webkit-tap-highlight-color: rgba(25, 73, 233, 0.05);
	}
	.site-list1 > ul > li > a:hover {
		background: #ecf2fe;
	}
	.site-list1 .area {
		display: inline-block;
		padding: 5px 10px;
		margin-right: 7px;
		background: #fff;
		border: 1px solid #7693f6;
		font-size: 13px;
		border-radius: 15px;
		color: #5477ee;
	}
	.site-list1 .area {
		@media screen and (max-width: 300px) {
			font-size: 10px;
		}
	}
	.article {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: space-between;
		padding: 0px !important;
		height: 35vh !important;

		.siteListWarper {
			width: 100%;

			.siteList {
				border-bottom: 1px solid #a1a1a1;
				padding: 15px !important;
			}
			@media screen and (max-width: 300px) {
				font-size: 13px;
			}
		}
		.tomonitoring {
			width: 100%;
		}
	}
</style>
